import { Controller } from "@hotwired/stimulus"

import { createFragment } from "../lib/helpers"

const preventEventDefault = event => event.preventDefault()
const removeAttachment = event => event.attachment.remove()

const removeTrailingNewlines = (event) => {
  const element = event.currentTarget

  if (element.innerHTML.match(/<br><\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -10) + "</div>"
    removeTrailingNewlines(event)
  }
}

const removeTrailingWhitespace = (event) => {
  const element = event.currentTarget

  if (element.innerHTML.match(/&nbsp;<\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -12) + "</div>"
    removeTrailingWhitespace(event)
  } else if (element.innerHTML.match(/&nbsp; <\/div>$/)) {
    element.innerHTML = element.innerHTML.slice(0, -13) + "</div>"
    removeTrailingWhitespace(event)
  }
}

const removeTrailingWhitespaceAndNewLines = (event) => removeTrailingNewlines(event) && removeTrailingWhitespace(event)

const sanitizePasteContent = (event) => {
  return
  const element = event.currentTarget
  const editor = element.editor

  if (event.paste.type == "text/plain") { return }

  const htmlFragment = createFragment(event.paste.html)
  const htmlAsString = Array.from(htmlFragment.children).map(c => c.innerText).join("")

  editor.setSelectedRange(event.paste.range)
  editor.insertString(htmlAsString)
}

export default class extends Controller {
  static values = {
    configure: {
      type: String,
      default: ""
    }
  }

  connect() {
    this.configure()
  }

  configure() {
    this.element.setAttribute("autocomplete", "off")
    this.element.addEventListener("trix-paste", sanitizePasteContent)

    !this.configurationIncludes("enable_fileupload") && this.disableUploads()
    !this.configurationIncludes("disable_trim") && this.enableTrim()
  }

  configurationIncludes(setting) {
    return this.configureValue.includes(setting)
  }

  disableUploads() {
    // disable file attachments from being accepted
    this.element.addEventListener("trix-file-accept", preventEventDefault)

    // if somehow an attachment does get added, remove anyway
    this.element.addEventListener("trix-attachment-add", removeAttachment)
  }

  enableTrim() {
    this.element.addEventListener("blur", removeTrailingWhitespaceAndNewLines)
  }

  disconnect() {
    this.element.removeEventListener("trix-file-accept", preventEventDefault)
    this.element.removeEventListener("trix-attachment-add", removeAttachment)
    this.element.removeEventListener("blur", removeTrailingWhitespaceAndNewLines)
  }
}