const ISO_8601_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/
const JSONParse = require("json-parse-safe")

import { log } from "./log"

export const coerce = (value) => {
  if (["undefined", undefined].includes(value)) {
    return undefined
  }
  if (["null", null].includes(value)) {
    return null
  }
  if (typeof (value) === "function") {
    return undefined
  }

  if (typeof (value) !== "string") {
    value = JSON.stringify(value)
  }
  if (!!value.match(ISO_8601_DATE_REGEX)) {
    return value
  }

  const parsed = JSONParse(value)
  return (parsed.error ? value : parsed.value)
}

export const MetaVars =
  new Proxy({}, {
    get(_base, property) {
      const matchingMeta = document.querySelector(`meta[name='${property}']`)

      if (!!matchingMeta) { return coerce(matchingMeta.content) }

      // Special cases
      switch (property) {
        case 'envProduction':
          return this.get(_base, "env") === "production"
        case 'envTest':
          return this.get(_base, "env") === "test"
        case 'envDevelopment':
          return this.get(_base, "env") === "development"
        default:
          break;
      }

      if (this.get(_base, "env") !== "development") return

      log(`MetaVar Property '${property}' has been read but not found`);
    }
  })

export const settingDefaultFalse = (settingValue) => settingValue === "true" ? true : false
export const settingDefaultTrue = (settingValue) => settingValue === "false" ? false : true

const elementsOrSelectorToElements = (elementsOrSelector) => {
  // string => NodeList
  if (typeof (elementsOrSelector) === "string") {
    elementsOrSelector = document.querySelectorAll(elementsOrSelector)
  }

  // single element => Array[NodeList]
  if (!elementsOrSelector.toString().includes("NodeList")) {
    elementsOrSelector = [elementsOrSelector]
  }

  // Flatten array
  if (elementsOrSelector.flat) {
    elementsOrSelector = elementsOrSelector.flat()
  }

  return elementsOrSelector
}

export const show = elementsOrSelector => removeClass(elementsOrSelector, "d-none")
export const showElement = show
export const showElements = show

export const hide = elementsOrSelector => addClass(elementsOrSelector, "d-none")
export const hideElement = hide
export const hideElements = hide

export const toggle = elementsOrSelector => toggleClass(elementsOrSelector, "d-none")
export const toggleElement = toggle
export const toggleElements = toggle

export const templateRenderer = (element, replacements) => {
  const template = element.content.cloneNode(true)

  Object.keys(replacements).forEach((idKey) => {
    template.querySelector(idKey).innerText = replacements[idKey]
  })

  return template
}

export const removeAllChildren = (element) => {
  while (element.lastChildElement) {
    element.removeChild(element.lastChildElement)
  }
  return element
}

export const userOnMobile = () => /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
export const getObjectSubsetKeys = (prependedIdentifier, data) => {
  let tempObj = {}

  Object.keys(data)
    .filter(key => key.startsWith(prependedIdentifier))
    .forEach(key => {
      let transformedKey = key.replace(prependedIdentifier, "")
      transformedKey = transformedKey[0].toLowerCase() + transformedKey.slice(1)

      tempObj[transformedKey] = data[key]
    })

  return tempObj
}
const addClassToElement = (element, className) => element.classList && element.classList.add && element.classList.add(className)
const removeClassFromElement = (element, className) => element.classList && element.classList.remove && element.classList.remove(className)
const toggleClassOnElement = (element, className) => element.classList && element.classList.toggle && element.classList.toggle(className)

export const addClass = (elementsOrSelector, className) => elementsOrSelectorToElements(elementsOrSelector).forEach(e => addClassToElement(e, className))
export const removeClass = (elementsOrSelector, className) => elementsOrSelectorToElements(elementsOrSelector).forEach(e => removeClassFromElement(e, className))
export const toggleClass = (elementsOrSelector, className) => elementsOrSelectorToElements(elementsOrSelector).forEach(e => toggleClassOnElement(e, className))

export const createFragment = (htmlString) => document.createRange().createContextualFragment(stripHtmlComments(htmlString))
export const fadeOutElement = (fadeTarget, fadeOutTimeInMs = 2000) => {
  const fadeOutTime = fadeOutTimeInMs / 1000.0

  fadeTarget.style.transition = `${fadeOutTime}s`
  fadeTarget.style.opacity = 0

  delay(fadeOutTimeInMs).then(() => fadeTarget.remove())
}
export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const scrollToAnchor = () => {
  if (window.scrolledToAnchor) return

  // Find Anchor
  const anchor = getUrlAnchor()
  if (!anchor) return

  // Find Anchor Element
  let anchorElement = null
  try {
    anchorElement = document.querySelector(anchor)
  } catch { }

  if (!anchorElement) return

  window.scrolledToAnchor = true
  anchorElement.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  })

  anchorElement.classList.add("highlight-and-fade")
}

export const getUrlAnchor = () => window.location.hash.split("?").shift()

// URL Parameter-related functions
export const getUrlParam = (name) => {
  const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href)
  if (results === null) { return null }

  return decodeURIComponent(results[1]).replace(/\+/g, ' ')
}

export const clearUrlParams = () => {
  if (window.location.href.indexOf("?") > -1) {
    const url = window.location.href
    const newUrl = url.slice(0, url.indexOf('?'))
    return window.history.pushState({}, "Causey", newUrl)
  }
}

export const addUrlParams = (params_object) => {
  clearUrlParams()

  const params_string = new URLSearchParams(params_object).toString()
  const url = `${window.location.href}?${params_string}`
  return window.history.pushState({}, "Causey", url)
}
// END / URL Parameter-related functions

export const isVisible = (element) => !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
export const firstParent = (rootElement, testFunc) => {
  let elem = rootElement
  let parent

  while (elem.parentElement) {
    if (testFunc(elem.parentElement)) {
      parent = elem.parentElement
      break
    }
    elem = elem.parentElement
  }

  return parent
}

export const getCoords = (elem) => {
  const box = elem.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

export const interpolateValues = (str, values) => {
  Object.keys(values).forEach(key => {
    const matcher = new RegExp(`::${key}::`, "g")

    str = str.replace(matcher, values[key])
  })

  return str
}

const BEGINNING_COMMENT = /^\<!--.*?--\>/
const ENDING_COMMENT = /\<!--.*?--\>$/
export const stripHtmlComments = (htmlStr) => {
  let html = htmlStr.trim()
  while (BEGINNING_COMMENT.test(html)) { html = html.replace(BEGINNING_COMMENT, "").trim() }
  while (ENDING_COMMENT.test(html)) { html = html.replace(ENDING_COMMENT, "").trim() }

  return html
}

export const triggerAction = () => {
  const triggerAction = getUrlParam("trigger_action") || "click"
  const triggerSelector = getUrlParam("trigger_selector")
  if (!triggerSelector) return

  const element = document.querySelector(triggerSelector)
  element && element[triggerAction] && element[triggerAction]()
}

export function loadHtmlContent(target, content) {
  target.querySelector("trix-editor").editor.loadHTML(content || "")
}