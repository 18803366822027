import { Controller } from "@hotwired/stimulus"
import { addClass, getUrlParam, scrollToAnchor } from "../lib/helpers"

export default class extends Controller {
  connect() {
    this.addRequiredClassToLabels()
    scrollToAnchor()
    this.focusInput()
  }

  addRequiredClassToLabels() {
    Array
      .from(document.querySelectorAll("[required='required']"))
      .map(element => element.parentElement.querySelectorAll(".form-label"))
      .forEach(labels => addClass(labels, "required"))
  }

  focusInput() {
    document.getElementById(getUrlParam("focus"))?.focus()
  }
}
